<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" label-width="82px">
            <el-row :gutter="20">
				<el-col :span="2">
                    <el-form-item label="渠道" prop="merchantNo" label-width="40px">
                 
                    </el-form-item> 
                </el-col>
                <el-col :span="6">
                    <el-form-item label="活动政策" prop="changeCheckStatus">
                        <el-select style="width:100%" v-model="queryParams.changeCheckStatus" size="small" placeholder="请选择活动政策">
                             
                        </el-select>
                    </el-form-item> 
                </el-col>
                <el-col :span="6">
                    <el-form-item label="代理商编号" prop="merchantNo">
                        <el-input
                            v-model="queryParams.agentNo"
                            clearable
                            placeholder="请输入代理商编号"
                            size="small" 
                        />
                        </el-form-item> 
                </el-col>
            </el-row>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery"  >
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery"  >
                    重置
                </el-button>
                <!-- <el-button type="primary" icon="el-icon-plus" size="mini"
                           @click="handleRateChange"  >
                    新增调价申请
                </el-button>  -->
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {MerchantApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
            }
        },
        mounted() {
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleRateChange(){
                this.$router.push({
                    name:'RateChangeApply',
                    query:{
                        isAdd:'1'
                    }
                })
            },
            handleSettleChange(){
                this.$router.push({
                    name:'SettleChangeApply',
                    query:{
                        isAdd:'1'
                    }
                })
            },
            handleStatusChange(){
                this.$router.push({
                    name:'StatusChangeApply',
                    query:{
                        isAdd:'1'
                    }
                })
            },
            handleExport() {
                this.$confirm("确定导出业务用户（包含机构/代理商）",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await MerchantApi.agentInfo.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                let [beginMerCreateTime, endMerCreateTime] = this.createDateRange;
                this.queryParams.beginMerCreateTime = beginMerCreateTime && (beginMerCreateTime + ' 00:00:00');
                this.queryParams.endMerCreateTime = endMerCreateTime && (endMerCreateTime + ' 23:59:59');

                let [beginChangeCreateTime, endChangeCreateTime] = this.updateDateRange;
                this.queryParams.beginChangeCreateTime = beginChangeCreateTime && (beginChangeCreateTime + ' 00:00:00');
                this.queryParams.endChangeCreateTime = endChangeCreateTime && (endChangeCreateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
